@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Heebo', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  line-height: 1.6;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #980000;
}

.headshot {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #980000;
}

.intro {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

.story-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.story-links a {
  text-decoration: none;
  color: inherit;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.story-links a:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #980000;
}

.story-links img {
  width: 100%;
  height: auto;
}

.story-links h2 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px;
  /* background-color: #f4f4f4; */
}

.story-links h2:hover {
  color: #980000;
}

.contact-info {
  /* background-color: #e9ecef; */
  border-radius: 8px;
  text-align: center;
}

.contact-info p {
  font-size: 1.1rem;
  margin: 5px 0;
}

.contact-info a {
  color: #980000;
}

.contact-info a:hover {
  text-decoration: underline;
}

.resume-download {
  margin-top: 40px;
  text-align: center;
}

.download-button {
  display: inline-block;
  /* Keep it inline with text */
  padding: 3px 8px;
  /* Smaller padding for a more compact button */
  font-size: 1rem;
  /* Slightly smaller font size */
  color: #fff;
  /* White text color */
  background-color: #980000;
  /* Button color */
  border: none;
  /* Remove default border */
  border-radius: 4px;
  /* Slightly rounded corners */
  text-decoration: none;
  /* Remove underline */
  margin-left: 10px;
  /* Space between the text and button */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth background color transition */
  cursor: pointer;
  /* Change cursor on hover */
}

.download-button:hover {
  background-color: #b30000;
  /* Darker shade on hover */
  transform: scale(1.05);
  /* Slightly enlarge on hover for a nice effect */
}

hr {
  border: none;
  /* Remove default border */
  border-top: 1px solid #ddd;
  /* Light grey line */
  margin: 20px 0;
  /* Spacing above and below */
  width: 100%;
  /* Full width */
  opacity: 0.6;
  /* Slightly transparent for subtlety */
}