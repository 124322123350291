body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

header {
  margin-bottom: 20px;
}

.intro {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.story-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.story-links a {
  text-decoration: none;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
}

.story-links a:hover {
  transform: scale(1.02);
}

.story-links img {
  width: 100%;
  height: auto;
}

h2 {
  padding: 10px;
  margin: 0;
}